import draggable from 'vuedraggable'
import MaterialResources from 'components/courseMaterial/MaterialResources';
import MaterialExam from 'components/courseMaterial/MaterialExam';
import MaterialProject from 'components/courseMaterial/MaterialProject';
import leasonTemplate from './leasonTemplate';
import lessonBatchTemplate from "./lessonBatchTemplate";
import {mapGetters} from 'vuex';
import _ from "underscore";

export default {
    name: "QualityCourseCreate",
    display: "Clone",
    components: {
        draggable,
        MaterialResources,
        MaterialProject,
        MaterialExam,
        leasonTemplate,
        lessonBatchTemplate
    },
    data() {
        return {
            //添加/编辑的title类型
            titleType: '',
            //弹窗的类型：add、edit等
            distinguishBtn: 0, //新增类型：0:章； 1：节
            //是否显示添加章节弹窗
            dialogAddZhang: false,
            //添加章节表单
            addZhangForm: {
                name: ''
            },
            rules: {
                name: [
                    {required: true, message: '请输入章标题', trigger: 'blur'},
                    {min: 3, max: 20, message: '长度在3到5个字符', trigger: 'blur'}
                ]
            },
            //章节数据
            chapters: [],
            //右侧默认显示的页面
            tabName: 'resources',
            showDialogMp4: false,
            videoSrc: null,
            courseType: 1,
            courseName: '',
            id: null,
            courseId: null,
            curMode: '0',
            editIndex: null,
            materialCheckeds: [],
            examCheckeds: []
        }
    },
    created() {
    },
    mounted() {
    },
    watch: {
        property: {
            immediate: true,
            handler() {
                if (!localStorage.getItem('token')) {
                    localStorage.removeItem('token');
                    this.$router.push('/admin/login');
                }
                this.courseType = this.getLesson.course_type;
                this.courseName = this.getLesson.course_name;
                if (this.getLesson.course_id) {
                    this.courseId = this.getLesson.course_id;
                    this.chapters = this.getLesson.course_content;
                }
            }
        }
    },
    computed: {
        ...mapGetters(['getLesson', 'initData', 'courseEditInfoGetter']),
        courseTypeName: function () {
            let courseTypeTitle = '';
            switch (this.courseType) {
                case 1:
                    courseTypeTitle = '精品课程';
                    break;
                case 2:
                    courseTypeTitle = '在线课程';
                    break;
                default:
                    courseTypeTitle = '精品课程';
                    break;
            }
            return courseTypeTitle;
        }
    },
    methods: {
        setSecIndex(val) {
            this.$refs.materials.initChecks();
            this.editIndex = val;
        },
        getMaterialRes(val) {
            let indexArr = this.editIndex.split('|');
            let sectionIns = this.chapters[indexArr[0]].children[indexArr[1]];
            let resourceArr = sectionIns.children;
            sectionIns.children = _.union(sectionIns.children, val);
            console.log(sectionIns, 8888)
        },
        //添加章/节按钮
        addZhang(command) {
            if (this.chapters.length === 0 && command === '1') {
                this.$message({
                    type: 'warning',
                    message: '请先添加章',
                    duration: 1000
                });
                return false;
            }
            if (command === '0') {
                this.titleType = '添加章';
                this.distinguishBtn = 0;
            }
            if (command === '1') {
                if (this.$store.state.undone) {
                    this.$message({
                        type: 'warning',
                        message: '有新增的节未完成设置~',
                    });
                    return false;
                }
                this.titleType = '添加节';
                this.distinguishBtn = 1;
            }
            this.dialogAddZhang = !this.dialogAddZhang;
        },
        onAdd(evt) {
            evt.target.className = 'move-area';
        },
        resourcePreview(file_path, file_type) {
            let videoIns = document.getElementById("videoIns");
            if (file_type === "mp4") {
                this.showDialogMp4 = true;
                this.videoSrc = file_path;
            } else {
                window.open(file_path);
            }
        },
        resetAddZhangForm() {
            this.$refs['addZhangForm'].resetFields();
            this.addZhangForm.name = '';
        },
        closeDialogMp4() {
            this.showDialogMp4 = false;
        },
        submitZhangForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.distinguishBtn === 0) {
                        let chapter = {
                            name: this.addZhangForm.name,
                            type: 1,
                            children: [],
                            expand: false
                        };
                        this.chapters.push(chapter);
                    } else if (this.distinguishBtn === 1) {
                        let chapter = this.chapters[this.chapters.length - 1];
                        chapter.children.push({
                            name: this.addZhangForm.name,
                            type: 2,
                            children: []
                        });
                    }
                    this.$refs[formName].resetFields();
                    this.dialogAddZhang = false;
                } else {
                    return false;
                }
            });

        },
        saveData(type) {
            if (this.chapters.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请添加课程目录以及资源'
                });
                return false;
            }
            if (this.$store.state.undone) {
                this.$message({
                    type: 'warning',
                    message: '请完成课程章节的创建'
                });
                return false;
            }

            if (!this.$common.formatChapters(this.chapters)) {
                this.$message({
                    type: 'warning',
                    message: '课程目录只支持（章-节-资源）3层-层级结构！,请切换到拖拽模式，检查层级结构',
                    duration: 3000
                });
                return false;
            }

            if (!this.$common.checkChapters(this.chapters)) {
                this.$message({
                    type: 'warning',
                    message: '课程必须添加资源！',
                    duration: 3000
                });
                return false;
            }
            this.format(this.chapters);
            if (this.courseId) {
                this.$store.dispatch('setLessonContent', {
                    ...this.getLesson,
                    course_content: this.chapters,
                    course_id: this.courseId
                });
            } else {
                this.$store.dispatch('setLessonContent', {...this.getLesson, course_content: this.chapters});
            }
            this.$http.axiosPost(this.$api.createCourse, this.getLesson, (res) => {
                if (res.code === 200) {
                    this.courseId = parseInt(res.id);
                    this.saveCourse();
                    if (type === 1) {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        });
                    } else {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.$store.dispatch('setLessonContent', {...this.getLesson, ...this.initData});
                                this.$router.push('/admin/courseManage/index');
                            }
                        });
                    }
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg
                    })
                }
            }, (err) => {
                console.log(err);
            });
        },
        format(chapters) {
            let len = chapters.length
            for (let i=len ;i >=0; i--) {
                if (chapters[i] == null) {
                    chapters.splice(i,1);
                } else {
                    this.format(chapters[i].children);
                }
            }
            return chapters;
        },
        returnCourses() {
            this.$confirm('此操作当前页面数据不做保存, 是否继续?', '提示', {}).then(() => {
                this.$router.push('/admin/courseManage/index');
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消返回操作~',
                    duration: 1000
                });
            });
        },
        saveCourse() {
            let id = this.$route.query.id;
            if(id === undefined){
                return
            }
            let courseInfo = this.courseEditInfoGetter;
            courseInfo.course_id = this.courseId;
            let obj = {};
            this.$http.axiosPost(this.$api.contents_saveCourseHome, courseInfo, res => {
                if (res.code == 200) {
                    //清空store的数据
                    this.$store.dispatch('setCourseEditInfo', obj);
                    this.$message.success(res.msg)
                } else {
                    this.$message.warning(res.msg)
                }
            })
        }
    }
}
